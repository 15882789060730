import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Home from '@interco/icons/build-v4/orangeds/MD/home'
import Layout from 'src/layouts/BaseLayout'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Link } from 'gatsby'
import { FixedIncome } from 'src/components/InvestmentSimulators'
import InvestmentsTab from 'src/components/InvestmentsTab'
import FAQ from 'src/components/Faq'
import BlogArticles from 'src/components/BlogArticles'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import QrCode from './assets/images/qr_tesouro.png'
// import Qr_Code from './assets/images/qr-code-modal-abrir-conta-digital.png'
import QrCodeKids from './assets/images/qrcode-tesouro-direto-conta-kids.png'
import pageContext from './pageContext.json'
import { Section, FAQSection, Box } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import headerJson from './assets/data/header.json'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'

const { qrCodeContaKidsAberturaDeContasInvestimentos } = qrcodeInvestimentsData

function RendaFixaCDB () {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const width = useWidth(300)

  const dataLayerQR: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Tesouro Direto: O investimento mais seguro do país',
    element_action: 'click button',
    element_name: 'Quero conhecer',
    element_previous: 'Comece a investir agora',
  }

  const OpenAccountModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
        qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={QrCode}
        dataLayer={dataLayerQR}
        agoraBaixeOAppLink='https://inter-co.onelink.me/Qyu7/q2ekb17g'
        deepLink='https://inter-co.onelink.me/Qyu7/gzu5o6rr'
      />
    </Modal>
  )

  const dataLayer: IDataLayerParams = {
    section: 'dobra_06',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  return (
    <Layout pageContext={pageContext}>
      {OpenAccountModal}
      <Section className='pt-4'>
        <div className='container'>
          <div className='row d-flex align-items-center justify-content-around'>
            <div className='col-12 col-md-6 order-md-last mb-3'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/tesouro-hero-1440/image.webp'
                altDescription=''
                arrayNumbers={[ 312, 366, 454, 576 ]}
                arrayNumbersHeight={[ 258, 277, 376, 476 ]}
              />
            </div>
            <div className='col-12 col-md-5 pr-md-0'>
              <div className='mb-3'>
                <div className='bread'>
                  <Link
                    to='/'
                    className='d-md-inline'
                  ><Home height={24} width={24} color='#6A6E81' />
                  </Link>
                  <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                  <Link
                    to='/pra-voce/investimentos/'
                    className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                  >
                    {headerJson.breadcrumb[0]}
                  </Link>
                  <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                  <Link
                    to='/pra-voce/investimentos/renda-fixa/'
                    className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mr-2'
                  >
                    {headerJson.breadcrumb[1]}
                  </Link>
                  <OrangeIcon icon='arrow-right' color='#161616' size='SM' className='ml-2' />
                  <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[2]}</p>
                </div>
              </div>
              <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-4 text-grayscale--500 fw-600 font-sora'>
                Invista no Tesouro Direto e ganhe o curso Xô Dureza
              </h1>
              <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--400 mb-4'>
                Ao escolher um título do Tesouro, como novo investidor, você ganha acesso gratuito à plataforma do Me Poupe+ para mudar de vida.
              </p>
              <div className='mt-2 mt-4 mb-5'>
                <a
                  style={{ paddingTop: 13 }}
                  title='Quero conhecer'
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100 d-block d-md-none'
                  href='https://inter-co.onelink.me/Qyu7/gzu5o6rr'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Investimentos que simplificam sua vida',
                        element_action: 'click button',
                        element_name: 'Quero conhecer',
                        redirect_url: 'https://inter-co.onelink.me/Qyu7/gzu5o6rr',
                      })
                  }}
                > Quero conhecer
                </a>
                <button
                  title='Quero conhecer'
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100 d-none d-md-block'
                  onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Tesouro Direto: O investimento mais seguro do país',
                        element_action: 'click button',
                        element_name: 'Quero conhecer',
                      })
                  }}
                >
                  Quero conhecer
                </button>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <section id='outros-investimentos' className='pt-md-5 pb-md-5'>
        <div className='container pb-lg-3'>
          <h2 className='fs-28 fs-md-42 mb-4 mt-2 text-grayscale--500 text-md-center font-sora'>
            Segurança para investir a longo prazo
          </h2>
          <div className='d-flex justify-content-center'>
            <div className='col-12 col-md-8 px-0'>
              <p className='fs-16 text-md-center text-grayscale--400'>
                Confira a simulação e veja como seu dinheiro pode render.
              </p>
            </div>
          </div>
          <div className='pt-4 d-md-none d-lg-none'>
            <h3 className='fs-17 text-grayscale--500 font-sora'>
              Veja um exemplo de como o seu dinheiro pode render.
            </h3>
          </div>
          <Box>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-4'>
                <div className='box-content mt-0 mt-lg-5 mb-4'>
                  <p className='fw-700 mb-1 text-grayscale--500 font-sora'>
                    Considerações utilizadas nos exemplos:
                  </p>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>Títulos Isentos de IR (LCI DI e Poupança) estão com taxas Gross-Up (consideramos a taxa como se o investimento fosse sujeito a IR, como um CDB). Assim, todos os valores demonstrados são brutos.</li>
                    <li>A expectativa é de que a <a href='https://blog.inter.co/taxa-selic-hoje/'>taxa Selic</a> oscile durante o período, do contrário, a rentabilidade poderá ser afetada.</li>
                    <li>Considera-se que o título do Tesouro Direto é a LFT (Tesouro Selic) e que sua rentabilidade é de 100% do CDI.</li>
                  </ul>
                </div>
                <div>
                  {
                    width >= 768 ? (
                      <button
                        title='Comece a investir agora'
                        className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100'
                        onClick={() => {
                        setIsModalOpen(true)
                        sendDatalayerEvent({
                          section: 'dobra_02',
                          section_name: 'Segurança para investir a longo prazo',
                          element_action: 'click button',
                          element_name: 'Comece a investir agora',
                        })
                        }}
                      >
                        Comece a investir agora
                      </button>
                    ) : (
                      <a
                        style={{ paddingTop: 13 }}
                        title='Quero conhecer'
                        className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100 d-block d-md-none'
                        href='https://inter-co.onelink.me/Qyu7/gzu5o6rr'
                        target='_blank'
                        rel='noreferrer'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_01',
                            section_name: 'Investimentos que simplificam sua vida',
                            element_action: 'click button',
                            element_name: 'Quero conhecer',
                            redirect_url: 'https://inter-co.onelink.me/Qyu7/gzu5o6rr',
                          })
                        }}
                      > Comece a investir agora
                      </a>
                    )
                  }
                </div>
              </div>
              <div className='col-12 col-lg-8 px-0'>
                <FixedIncome products='tesouro,poupanca' />
              </div>
            </div>
          </Box>
        </div>
      </section>

      <section id='outros-investimentos'>
        <div className='container pt-4 pb-md-5'>
          <h2 className='fs-28 fs-md-42 text-md-center text-grayscale--500 font-sora mb-2 mb-md-4'>
            Veja outros tipos de investimentos
          </h2>
          <InvestmentsTab
            section='dobra_3'
            elementAction='click button'
            section_name='Veja outros tipos de investimentpos'
          />
        </div>
      </section>

      <section id='novidades-investimentos' className='py-md-4'>
        <div className='container'>
          <div className='col-12 mb-4'>
            <h2 className='fs-28 mb-0 fs-lg-42 text-left text-lg-center text-grayscale--500 font-sora mb-4'>
              Os melhores conteúdos sobre investimentos
            </h2>
            <p className='text-left mt-1 text-lg-center text-grayscale--400 mb-0'>
              Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
            </p>
          </div>
          <BlogArticles
            type='investments'
            section='dobra_4'
            elementAction='click button'
            elementName='Saiba mais'
            sectionName='Os melhores conteúdos sobre investimentos'
          />
        </div>
      </section>

      <FAQSection id='perguntas-frequentes' className='bg-gray pt-5 pb-4 pb-md-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <h2 className='fs-28 fs-md-42 mb-4 text-md-center text-grayscale--500 font-sora'>
                Perguntas frequentes sobre o Tesouro Direto
              </h2>
              <p className='fs-16 text-md-center text-grayscale--400'>
                Tire suas dúvidas sobre o investimento no Tesouro Direto, que oferece diversas opções
                de títulos com diferentes prazos e rentabilidades.
              </p>
            </div>
            <FAQ
              className='summary-content'
              answerData={pageContext.structuredData.faq}
              searchBg='#ffffff'
            />
          </div>
        </div>
      </FAQSection>
      <DisclaimerInterInvest
        sendDatalayerEvent={sendDatalayerEvent}
        dataLayer={dataLayer}
        section='dobra_06'
      />
    </Layout>
  )
}

export default RendaFixaCDB
